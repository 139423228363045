
import GeneralData from "@/modules/accountingAccounts/components/GeneralData.vue";
import useAccountingAccount from "@/modules/accountingAccounts/composables/useAccountingAccount";
import toastr from "toastr";
import {ref} from "vue";
import router from "@/router";

export default {
    components: {GeneralData},
    setup() {
        const {clearForm, accountingAccountForm, createAccoutingAccount, catalogs} = useAccountingAccount()
        const sending = ref(false)

        clearForm();

        return {
            sending,
            catalogs,
            cancel: () => router.push({name: 'accounting_accounts'}),
            onSubmit: async () => {
                try {
                    sending.value = true
                    const {ok, message} = await createAccoutingAccount(accountingAccountForm.value)

                    if (ok) {
                        toastr.success('Su solicitud se ha procesado correctamente.')
                        await router.push({name: 'accounting_accounts'})
                    } else {
                        await toastr.error(message)
                    }
                } finally {
                    sending.value = false
                }
            }
        }
    }
}
